<template lang="pug">
  #overlay-weight-scale
    h1 Capture your weight reading
    icon.scale(data="@icon/overlay/weight-scale.svg")
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {},

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
